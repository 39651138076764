// // import logo from './logo.svg';
// // import './App.css';

// // function App() {
// //   return (
// //     <div className="App">
// //       <header className="App-header">
// //         <img src={logo} className="App-logo" alt="logo" />
// //         <p>
// //           Edit <code>src/App.js</code> and save to reload.
// //         </p>
// //         <a
// //           className="App-link"
// //           href="https://reactjs.org"
// //           target="_blank"
// //           rel="noopener noreferrer"
// //         >
// //           Learn React
// //         </a>
// //       </header>
// //     </div>
// //   );
// // }

// // export default App;















import React from 'react';
import { Toaster } from 'react-hot-toast'; // Import Toaster
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ProductsSection from './components/ProductsSection';
import AgentsSection from './components/AgentsSection';
import TeamSection from './components/TeamSection';
import ClientShowcase from './components/ClientShowcase';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import LatentForceChat from './components/LatentForceChat'
import ArticlesSection from './components/ArticlesSection';
import BlogsSection from './components/BlogSection';


function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-violet-50 to-indigo-50 dark:from-gray-900 dark:via-violet-950 dark:to-indigo-950 transition-colors duration-300">
      {/* Add Toaster at the root level */}
      <Toaster />
      <Navbar />
      <HeroSection />
      <ProductsSection />
      <ArticlesSection/>
      {/* <BlogsSection/> */}
      <AgentsSection />
      <ClientShowcase />
      <TeamSection />
      <ContactSection />
      <Footer />
      <LatentForceChat />
    </div>
  );
}

export default App;


/////////////

/////////////////////////////////////////////////////////////////////////// Updated App.js with React Router implementation

// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { Toaster } from 'react-hot-toast';
// import Navbar from './components/Navbar';
// import HeroSection from './components/HeroSection';
// import ProductsSection from './components/ProductsSection';
// import AgentsSection from './components/AgentsSection';
// import TeamSection from './components/TeamSection';
// import ClientShowcase from './components/ClientShowcase';
// import ContactSection from './components/ContactSection';
// import Footer from './components/Footer';
// import LatentForceChat from './components/LatentForceChat';
// import ArticlesSection from './components/ArticlesSection';
// import BlogsSection from './components/BlogSection';
// import BlogPost from './components/BlogPost';
// import ExtractorsBlogPost from './components/ExtractorsBlogPost'; // Import the custom Extractors blog component

// // Import blog data to be accessible throughout the app
// import { blogData } from './data/blogData';

// function HomePage() {
//   return (
//     <>
//       <HeroSection />
//       <ProductsSection />
//       <ArticlesSection />
//       <BlogsSection />
//       <AgentsSection />
//       <ClientShowcase />
//       <TeamSection />
//       <ContactSection />
//     </>
//   );
// }

// function App() {
//   return (
//     <Router>
//       <div className="min-h-screen bg-gradient-to-b from-white via-violet-50 to-indigo-50 dark:from-gray-900 dark:via-violet-950 dark:to-indigo-950 transition-colors duration-300">
//         <Toaster />
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<HomePage />} />
          
//           {/* Special route for the Extractors blog with custom component */}
//           <Route path="/blogs/extractors" element={<ExtractorsBlogPost />} />
          
//           {/* Dynamic routes for other blog posts */}
//           <Route path="/blogs/:blogSlug" element={<BlogPost blogData={blogData} />} />
//         </Routes>
//         <Footer />
//         <LatentForceChat />
//       </div>
//     </Router>
//   );
// }

// export default App;

// //////////////////////////////////////////////////////////////////
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { Toaster } from 'react-hot-toast';
// import Navbar from './components/Navbar';
// import HeroSection from './components/HeroSection';
// import ProductsSection from './components/ProductsSection';
// import AgentsSection from './components/AgentsSection';
// import TeamSection from './components/TeamSection';
// import ClientShowcase from './components/ClientShowcase';
// import ContactSection from './components/ContactSection';
// import Footer from './components/Footer';
// import LatentForceChat from './components/LatentForceChat';
// import ArticlesSection from './components/ArticlesSection';
// import BlogsSection from './components/BlogSection';
// import BlogPost from './components/BlogPost';
// import ExtractorsBlogPost from './components/ExtractorsBlogPost'; 
// import CleoBlogPost from './components/CleoBlogPost';


// import { blogData } from './data/blogData';
// import CodeAssistBlogPost from './components/CodeAssistBlogPost';
// import AgenticAIBlogPost from './components/AgenticAIBlogPost';
// import EducationAIBlogPost from './components/EducationAIBlogPost';
// import DecisionMakingAIBlogPost from './components/DecisionMakingAIBlogPost';
// import SpeedInAIBlogPost from './components/SpeedInAIBlogPost';
// import SupportToAdvisorBlogPost from './components/SupportToAdvisorBlogPost';
// import OnPremVsCloudBlogPost from './components/OnPremVsCloudBlogPost';
// import AITeammateBlogPost from './components/AITeammateBlogPost';

// function HomePage() {
//   return (
//     <>
//       <HeroSection />
//       <ProductsSection />
//       <ArticlesSection />
//       {/* <BlogsSection /> */}
//       <AgentsSection />
//       <ClientShowcase />
//       <TeamSection />
//       <ContactSection />
//     </>
//   );
// }

// function App() {
//   return (
//     <Router>
//       <div className="min-h-screen bg-gradient-to-b from-white via-violet-50 to-indigo-50 dark:from-gray-900 dark:via-violet-950 dark:to-indigo-950 transition-colors duration-300">
//         <Toaster />
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<HomePage />} />
          
//           {/* Special routes for custom blog pages */}
//           {/* <Route path="/blogs/extractors" element={<ExtractorsBlogPost />} />
//           <Route path="/blogs/cleo" element={<CleoBlogPost />} /> 
//           <Route path="/blogs/code-assist" element={<CodeAssistBlogPost />} />
//           <Route path="/blogs/agentic-ai-systems" element={<AgenticAIBlogPost />} />
//           <Route path="/blogs/automating-handwritten-evaluations" element={<EducationAIBlogPost />} />
//           <Route path="/blogs/ai-decision-making" element={<DecisionMakingAIBlogPost />} />
//           <Route path="/blogs/speed-in-ai" element={<SpeedInAIBlogPost />} />
//           <Route path="/blogs/support-to-advisor" element={<SupportToAdvisorBlogPost />} />
//           <Route path="/blogs/on-prem-vs-cloud" element={<OnPremVsCloudBlogPost />} />
//           <Route path="/blogs/ai-teammate" element={<AITeammateBlogPost />} />
          
//           <Route path="/blogs/:blogSlug" element={<BlogPost blogData={blogData} />} /> */}
//         </Routes>
//         <Footer />
//         <LatentForceChat />
//       </div>
//     </Router>
//   );
// }

// export default App;