// import React from 'react';
// import { motion } from 'framer-motion';
// import { ExternalLink, ArrowUpRight, BookOpen, Calendar, Clock } from 'lucide-react';

// const ArticlesSection = () => {
//   const articles = [
//     {
//       id: 1,
//       title: "Bengaluru-based AI Startup LatentForce.AI Enables LLM-Powered Data Extraction",
//       source: "Analytics India Magazine",
//       date: "December 2024",
//       readTime: "5 min read",
//       excerpt: "Discover how LatentForce.AI is transforming data extraction with advanced LLM technology, serving enterprises across industries.",
//       link: "https://www.linkedin.com/posts/analytics-india-magazine_bengaluru-based-ai-driven-startup-latentforceai-activity-7277266141595508736-z91a"
//     },
//     {
//       id: 2,
//       title: "LatentForce.AI Revolutionizes Data Extraction with LLMs",
//       source: "AI Plus Info",
//       date: "December 2024",
//       readTime: "4 min read",
//       excerpt: "An in-depth look at how LatentForce.AI's innovative approach to data extraction is setting new industry standards.",
//       link: "https://www.aiplusinfo.com/blog/latentforce-ai-revolutionizes-data-extraction-with-llms/"
//     },
//     {
//       id: 3,
//       title: "LatentForce.AI Introduces Extractors.AI: Revolutionizing Data Extraction",
//       source: "CXO Digital Pulse",
//       date: "December 2024",
//       readTime: "6 min read",
//       excerpt: "Exploring the launch of Extractors.AI and its impact on automated data processing workflows.",
//       link: "https://www.cxodigitalpulse.com/latentforce-ai-introduces-extractors-ai-revolutionizing-data-extraction-with-generative-ai/"
//     },
//     {
//       id: 4,
//       title: "LatentForce.AI Enables LLM-Powered Document Conversion Tasks",
//       source: "Analytics India Magazine",
//       date: "December 2024",
//       readTime: "4 min read",
//       excerpt: "Learn about the innovative document conversion capabilities powered by LatentForce.AI's advanced LLM technology.",
//       link: "https://analyticsindiamag.com/ai-news-updates/bengaluru-based-latentforce-ai-enables-llm-powered-data-extraction-document-conversion-tasks/"
//     }
//     ,
//     {
//       id: 5,
//       title: "LatentForce.ai at AI Day Bangalore: Leading the Way in Multimodal AI Innovation for the Future",
//       source: "LinkedIn",
//       date: "January 2025",
//       readTime: "2 min read",
//       excerpt:"Unveiling the Power of Multimodal LLM Agents and AI-Driven Document Processing in Revolutionizing Industry Workflows.", 
//       link: "https://www.linkedin.com/feed/update/urn:li:activity:7287061311253856256"
//     }
//     ,
//     {
//       id: 6,
//       title: "LatentForce.ai & Excelsoft: Transforming Education with Generative AI for Smarter, Personalized Learning.",
//       source: "LinkedIn",
//       date: "January 2025",
//       readTime: "2 min read",
//       excerpt: "LatentForce.AI partners with ExcelSoft to transform educational technology through advanced AI solutions.",
//       link: "https://www.linkedin.com/posts/excelsoftcorp_partnership-generativeai-edtechinnovation-activity-7285585701981560833-FyhV?utm_source=share&utm_medium=member_desktop"
//     }
//   ];

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.1
//       }
//     }
//   };

//   const cardVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.5
//       }
//     }
//   };

//   return (
//     <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800" id="articles">
//       <motion.div
//         className="max-w-7xl mx-auto"
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true }}
//         variants={containerVariants}
//       >
//         {/* Section Header */}
//         <motion.div variants={cardVariants} className="text-center mb-16">
//           <h2 className="text-4xl md:text-5xl font-bold mb-6">
//             <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//               In the News
//             </span>
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
//             See How LatentForce.ai is making waves in the Tech industry
//           </p>
//         </motion.div>

//         {/* Articles Grid */}
//         <motion.div 
//           className="grid grid-cols-1 md:grid-cols-2 gap-8"
//           variants={containerVariants}
//         >
//           {articles.map((article) => (
//             <motion.a
//               key={article.id}
//               href={article.link}
//               target="_blank"
//               rel="noopener noreferrer"
//               variants={cardVariants}
//               whileHover={{ scale: 1.02 }}
//               className="group"
//             >
//               <div className="h-full rounded-2xl overflow-hidden bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700">
//                 <div className="p-8">
//                   {/* Article Header */}
//                   <div className="flex items-center justify-between mb-4">
//                     <span className="px-4 py-1 rounded-full text-sm font-medium bg-purple-100 dark:bg-purple-900 text-purple-600 dark:text-purple-300">
//                       {article.source}
//                     </span>
//                     <div className="flex items-center space-x-4 text-gray-400">
//                       <div className="flex items-center">
//                         <Calendar className="w-4 h-4 mr-2" />
//                         <span className="text-sm">{article.date}</span>
//                       </div>
//                       <div className="flex items-center">
//                         <Clock className="w-4 h-4 mr-2" />
//                         <span className="text-sm">{article.readTime}</span>
//                       </div>
//                     </div>
//                   </div>

//                   {/* Article Title */}
//                   <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors duration-300">
//                     {article.title}
//                   </h3>

//                   {/* Article Excerpt */}
//                   <p className="text-gray-600 dark:text-gray-300 mb-6">
//                     {article.excerpt}
//                   </p>

//                   {/* Article Footer */}
//                   <div className="flex items-center justify-between mt-6 pt-6 border-t border-gray-100 dark:border-gray-700">
//                     <div className="flex items-center text-purple-600 dark:text-purple-400">
//                       <BookOpen className="w-5 h-5 mr-2" />
//                       <span className="font-medium">Read article</span>
//                     </div>
//                     <ArrowUpRight className="w-5 h-5 text-purple-600 dark:text-purple-400 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform duration-300" />
//                   </div>
//                 </div>
//               </div>
//             </motion.a>
//           ))}
//         </motion.div>

//       </motion.div>
//     </section>
//   );
// };

// export default ArticlesSection;
// import React, { useState, useEffect, useRef } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { ArrowUpRight, BookOpen, Calendar, Clock, ChevronLeft, ChevronRight } from 'lucide-react';

// const ArticlesSection = () => {
//   const articles = [
//     {
//       id: 1,
//       title: "Bengaluru-based AI Startup LatentForce.AI Enables LLM-Powered Data Extraction",
//       source: "Analytics India Magazine",
//       date: "December 2024",
//       readTime: "5 min read",
//       excerpt: "Discover how LatentForce.AI is transforming data extraction with advanced LLM technology, serving enterprises across industries.",
//       link: "https://www.linkedin.com/posts/analytics-india-magazine_bengaluru-based-ai-driven-startup-latentforceai-activity-7277266141595508736-z91a"
//     },
//     {
//       id: 2,
//       title: "LatentForce.AI Revolutionizes Data Extraction with LLMs",
//       source: "AI Plus Info",
//       date: "December 2024",
//       readTime: "4 min read",
//       excerpt: "An in-depth look at how LatentForce.AI's innovative approach to data extraction is setting new industry standards.",
//       link: "https://www.aiplusinfo.com/blog/latentforce-ai-revolutionizes-data-extraction-with-llms/"
//     },
//     {
//       id: 3,
//       title: "LatentForce.AI Introduces Extractors.AI: Revolutionizing Data Extraction",
//       source: "CXO Digital Pulse",
//       date: "December 2024",
//       readTime: "6 min read",
//       excerpt: "Exploring the launch of Extractors.AI and its impact on automated data processing workflows.",
//       link: "https://www.cxodigitalpulse.com/latentforce-ai-introduces-extractors-ai-revolutionizing-data-extraction-with-generative-ai/"
//     },
//     {
//       id: 4,
//       title: "LatentForce.AI Enables LLM-Powered Document Conversion Tasks",
//       source: "Analytics India Magazine",
//       date: "December 2024",
//       readTime: "4 min read",
//       excerpt: "Learn about the innovative document conversion capabilities powered by LatentForce.AI's advanced LLM technology.",
//       link: "https://analyticsindiamag.com/ai-news-updates/bengaluru-based-latentforce-ai-enables-llm-powered-data-extraction-document-conversion-tasks/"
//     },
//     {
//       id: 5,
//       title: "LatentForce.ai at AI Day Bangalore: Leading the Way in Multimodal AI Innovation for the Future",
//       source: "LinkedIn",
//       date: "January 2025",
//       readTime: "2 min read",
//       excerpt:"Unveiling the Power of Multimodal LLM Agents and AI-Driven Document Processing in Revolutionizing Industry Workflows.", 
//       link: "https://www.linkedin.com/feed/update/urn:li:activity:7287061311253856256"
//     },
//     {
//       id: 6,
//       title: "LatentForce.ai & Excelsoft: Transforming Education with Generative AI for Smarter, Personalized Learning.",
//       source: "LinkedIn",
//       date: "January 2025",
//       readTime: "2 min read",
//       excerpt: "LatentForce.AI partners with ExcelSoft to transform educational technology through advanced AI solutions.",
//       link: "https://www.linkedin.com/posts/excelsoftcorp_partnership-generativeai-edtechinnovation-activity-7285585701981560833-FyhV?utm_source=share&utm_medium=member_desktop"
//     }
//   ];

//   // Reverse the articles array to start from the last ID
//   const reversedArticles = [...articles].reverse();
//   const articlesPerPage = 2; // Back to showing 2 cards at a time for better visuals
//   const [currentIndex, setCurrentIndex] = useState(0);

//   // State to track direction of animation
//   const [direction, setDirection] = useState(1); // 1 for right, -1 for left
//   const [isPaused, setIsPaused] = useState(false);
//   const intervalRef = useRef(null);

//   useEffect(() => {
//     // Set up auto-scroll interval
//     startAutoScroll();
    
//     // Clean up on unmount
//     return () => {
//       if (intervalRef.current) {
//         clearInterval(intervalRef.current);
//       }
//     };
//   }, []); // Only run on mount, not when slides change

//   const startAutoScroll = () => {
//     // Clear any existing interval
//     if (intervalRef.current) {
//       clearInterval(intervalRef.current);
//     }
    
//     // Set new interval
//     intervalRef.current = setInterval(() => {
//       if (!isPaused) {
//         nextSlide();
//       }
//     }, 7000); // ← Change this value (in milliseconds) to adjust the time interval
//   };

//   const pauseAutoScroll = () => {
//     setIsPaused(true);
//   };

//   const resumeAutoScroll = () => {
//     setIsPaused(false);
//   };

//   const nextSlide = () => {
//     setDirection(1);
//     setCurrentIndex((prevIndex) => 
//       prevIndex + articlesPerPage >= reversedArticles.length 
//         ? 0 
//         : prevIndex + articlesPerPage
//     );
//   };

//   const prevSlide = () => {
//     setDirection(-1);
//     setCurrentIndex((prevIndex) => 
//       prevIndex - articlesPerPage < 0 
//         ? Math.floor((reversedArticles.length - 1) / articlesPerPage) * articlesPerPage 
//         : prevIndex - articlesPerPage
//     );
//   };

//   // Get current visible articles
//   const getCurrentArticles = () => {
//     const result = [];
//     for (let i = 0; i < articlesPerPage; i++) {
//       const articleIndex = (currentIndex + i) % reversedArticles.length;
//       result.push(reversedArticles[articleIndex]);
//     }
//     return result;
//   };

//   const totalPages = Math.ceil(reversedArticles.length / articlesPerPage);
//   const currentPage = Math.floor(currentIndex / articlesPerPage) + 1;

//   // Article Card Component
//   const ArticleCard = ({ article }) => (
//     <a
//       href={article.link}
//       target="_blank"
//       rel="noopener noreferrer"
//       className="group block h-full"
//       onMouseEnter={pauseAutoScroll}
//       onMouseLeave={resumeAutoScroll}
//     >
//       <div className="h-full rounded-2xl overflow-hidden bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700 flex flex-col">
//         <div className="p-8 flex flex-col flex-grow">
//           {/* Article Header */}
//           <div className="flex items-center justify-between mb-4">
//             <span className="px-4 py-1 rounded-full text-sm font-medium bg-purple-100 dark:bg-purple-900 text-purple-600 dark:text-purple-300">
//               {article.source}
//             </span>
//             <div className="flex items-center space-x-4 text-gray-400">
//               <div className="flex items-center">
//                 <Calendar className="w-4 h-4 mr-2" />
//                 <span className="text-sm">{article.date}</span>
//               </div>
//               <div className="flex items-center">
//                 <Clock className="w-4 h-4 mr-2" />
//                 <span className="text-sm">{article.readTime}</span>
//               </div>
//             </div>
//           </div>

//           {/* Article Title */}
//           <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors duration-300">
//             {article.title}
//           </h3>

//           {/* Article Excerpt */}
//           <p className="text-gray-600 dark:text-gray-300 mb-6 flex-grow">
//             {article.excerpt}
//           </p>

//           {/* Article Footer */}
//           <div className="flex items-center justify-between mt-auto pt-6 border-t border-gray-100 dark:border-gray-700">
//             <div className="flex items-center text-purple-600 dark:text-purple-400">
//               <BookOpen className="w-5 h-5 mr-2" />
//               <span className="font-medium">Read article</span>
//             </div>
//             <ArrowUpRight className="w-5 h-5 text-purple-600 dark:text-purple-400 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform duration-300" />
//           </div>
//         </div>
//       </div>
//     </a>
//   );

//   return (
//     <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800" id="articles">
//       <div className="max-w-7xl mx-auto">
//         {/* Section Header */}
//         <div className="text-center mb-16">
//           <h2 className="text-4xl md:text-5xl font-bold mb-6">
//             <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//               In the News
//             </span>
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
//             See How LatentForce.ai is making waves in the Tech industry
//           </p>
//         </div>

//         {/* Carousel Container */}
//         <div className="relative px-16">
//           {/* Navigation Buttons */}
//           <motion.button 
//             onClick={prevSlide}
//             className="absolute left-0 top-1/3 -translate-y-1/2 z-10 bg-white dark:bg-gray-800 p-3 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-all focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
//             aria-label="Previous articles"
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.9 }}
//           >
//             <ChevronLeft className="w-6 h-6 text-purple-600 dark:text-purple-400" />
//           </motion.button>
          
//           <motion.button 
//             onClick={nextSlide}
//             className="absolute right-0 top-1/3 -translate-y-1/2 z-10 bg-white dark:bg-gray-800 p-3 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-all focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
//             aria-label="Next articles"
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.9 }}
//           >
//             <ChevronRight className="w-6 h-6 text-purple-600 dark:text-purple-400" />
//           </motion.button>

//           {/* Articles Display */}
//           <div className="overflow-hidden">
//             <AnimatePresence mode="wait" initial={false}>
//               <motion.div 
//                 key={currentIndex}
//                 initial={{ opacity: 0, x: direction * 50 }}
//                 animate={{ opacity: 1, x: 0 }}
//                 exit={{ opacity: 0, x: direction * -50 }}
//                 transition={{ 
//                   type: "spring", 
//                   stiffness: 120, 
//                   damping: 20,
//                   mass: 1
//                 }}
//                 className="grid grid-cols-1 md:grid-cols-2 gap-8"
//               >
//               {getCurrentArticles().map((article) => (
//                 <motion.div
//                   key={article.id}
//                   initial={{ opacity: 0, y: 20 }}
//                   animate={{ opacity: 1, y: 0 }}
//                   transition={{ 
//                     duration: 0.5,
//                     delay: 0.1
//                   }}
//                 >
//                   <ArticleCard article={article} />
//                 </motion.div>
//               ))}
//             </motion.div>
//             </AnimatePresence>
//           </div>
          
//           {/* Pagination Indicator */}
//           <div className="flex justify-center mt-8 space-x-2">
//             {Array.from({ length: totalPages }).map((_, index) => (
//               <button
//                 key={index}
//                 onClick={() => setCurrentIndex(index * articlesPerPage)}
//                 className={`h-2 rounded-full transition-all duration-300 ${
//                   index === currentPage - 1
//                     ? "w-8 bg-purple-600 dark:bg-purple-400"
//                     : "w-2 bg-gray-300 dark:bg-gray-600"
//                 }`}
//                 aria-label={`Go to page ${index + 1}`}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ArticlesSection;

import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowUpRight, BookOpen, Calendar, Clock, ChevronLeft, ChevronRight } from 'lucide-react';

const ArticlesSection = () => {
  const articles = [
    {
      id: 1,
      title: "Bengaluru-based AI Startup LatentForce.AI Enables LLM-Powered Data Extraction",
      source: "Analytics India Magazine",
      date: "December 2024",
      readTime: "5 min read",
      excerpt: "Discover how LatentForce.AI is transforming data extraction with advanced LLM technology, serving enterprises across industries.",
      link: "https://www.linkedin.com/posts/analytics-india-magazine_bengaluru-based-ai-driven-startup-latentforceai-activity-7277266141595508736-z91a"
    },
    {
      id: 2,
      title: "LatentForce.AI Revolutionizes Data Extraction with LLMs",
      source: "AI Plus Info",
      date: "December 2024",
      readTime: "4 min read",
      excerpt: "An in-depth look at how LatentForce.AI's innovative approach to data extraction is setting new industry standards.",
      link: "https://www.aiplusinfo.com/blog/latentforce-ai-revolutionizes-data-extraction-with-llms/"
    },
    {
      id: 3,
      title: "LatentForce.AI Introduces Extractors.AI: Revolutionizing Data Extraction",
      source: "CXO Digital Pulse",
      date: "December 2024",
      readTime: "6 min read",
      excerpt: "Exploring the launch of Extractors.AI and its impact on automated data processing workflows.",
      link: "https://www.cxodigitalpulse.com/latentforce-ai-introduces-extractors-ai-revolutionizing-data-extraction-with-generative-ai/"
    },
    {
      id: 4,
      title: "LatentForce.AI Enables LLM-Powered Document Conversion Tasks",
      source: "Analytics India Magazine",
      date: "December 2024",
      readTime: "4 min read",
      excerpt: "Learn about the innovative document conversion capabilities powered by LatentForce.AI's advanced LLM technology.",
      link: "https://analyticsindiamag.com/ai-news-updates/bengaluru-based-latentforce-ai-enables-llm-powered-data-extraction-document-conversion-tasks/"
    },
    {
      id: 5,
      title: "LatentForce.ai at AI Day Bangalore: Leading the Way in Multimodal AI Innovation for the Future",
      source: "LinkedIn",
      date: "January 2025",
      readTime: "2 min read",
      excerpt:"Unveiling the Power of Multimodal LLM Agents and AI-Driven Document Processing in Revolutionizing Industry Workflows.", 
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7287061311253856256"
    },
    {
      id: 6,
      title: "LatentForce.ai & Excelsoft: Transforming Education with Generative AI for Smarter, Personalized Learning.",
      source: "LinkedIn",
      date: "January 2025",
      readTime: "2 min read",
      excerpt: "LatentForce.AI partners with ExcelSoft to transform educational technology through advanced AI solutions.",
      link: "https://www.linkedin.com/posts/excelsoftcorp_partnership-generativeai-edtechinnovation-activity-7285585701981560833-FyhV?utm_source=share&utm_medium=member_desktop"
    }
  ];

  // Reverse the articles array to start from the last ID
  const reversedArticles = [...articles].reverse();
  const articlesPerPage = 2; // Back to showing 2 cards at a time for better visuals
  const [currentIndex, setCurrentIndex] = useState(0);

  // State to track direction of animation
  const [direction, setDirection] = useState(1); // 1 for right, -1 for left
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Set up auto-scroll interval
    startAutoScroll();
    
    // Clean up on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Only run on mount, not when slides change

  const startAutoScroll = () => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    // Set new interval
    intervalRef.current = setInterval(() => {
      if (!isPaused) {
        nextSlide();
      }
    }, 7000); // ← Change this value (in milliseconds) to adjust the time interval
  };

  const pauseAutoScroll = () => {
    setIsPaused(true);
  };

  const resumeAutoScroll = () => {
    setIsPaused(false);
  };

  const nextSlide = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => 
      prevIndex + articlesPerPage >= reversedArticles.length 
        ? 0 
        : prevIndex + articlesPerPage
    );
    // Reset the timer when manually navigating
    startAutoScroll();
  };

  const prevSlide = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => 
      prevIndex - articlesPerPage < 0 
        ? Math.floor((reversedArticles.length - 1) / articlesPerPage) * articlesPerPage 
        : prevIndex - articlesPerPage
    );
    // Reset the timer when manually navigating
    startAutoScroll();
  };

  // Get current visible articles
  const getCurrentArticles = () => {
    const result = [];
    for (let i = 0; i < articlesPerPage; i++) {
      const articleIndex = (currentIndex + i) % reversedArticles.length;
      result.push(reversedArticles[articleIndex]);
    }
    return result;
  };

  const totalPages = Math.ceil(reversedArticles.length / articlesPerPage);
  const currentPage = Math.floor(currentIndex / articlesPerPage) + 1;

  // Article Card Component
  const ArticleCard = ({ article }) => (
    <a
      href={article.link}
      target="_blank"
      rel="noopener noreferrer"
      className="group block h-full"
      onMouseEnter={pauseAutoScroll}
      onMouseLeave={resumeAutoScroll}
    >
      <div className="h-full rounded-2xl overflow-hidden bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700 flex flex-col">
        <div className="p-8 flex flex-col flex-grow">
          {/* Article Header */}
          <div className="flex items-center justify-between mb-4">
            <span className="px-4 py-1 rounded-full text-sm font-medium bg-purple-100 dark:bg-purple-900 text-purple-600 dark:text-purple-300">
              {article.source}
            </span>
            <div className="flex items-center space-x-4 text-gray-400">
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-2" />
                <span className="text-sm">{article.date}</span>
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-2" />
                <span className="text-sm">{article.readTime}</span>
              </div>
            </div>
          </div>

          {/* Article Title */}
          <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors duration-300">
            {article.title}
          </h3>

          {/* Article Excerpt */}
          <p className="text-gray-600 dark:text-gray-300 mb-6 flex-grow">
            {article.excerpt}
          </p>

          {/* Article Footer */}
          <div className="flex items-center justify-between mt-auto pt-6 border-t border-gray-100 dark:border-gray-700">
            <div className="flex items-center text-purple-600 dark:text-purple-400">
              <BookOpen className="w-5 h-5 mr-2" />
              <span className="font-medium">Read article</span>
            </div>
            <ArrowUpRight className="w-5 h-5 text-purple-600 dark:text-purple-400 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform duration-300" />
          </div>
        </div>
      </div>
    </a>
  );

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800" id="articles">
      <div className="max-w-7xl mx-auto">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              In the News
            </span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            See How LatentForce.ai is making waves in the Tech industry
          </p>
        </div>

        {/* Carousel Container */}
        <div className="relative px-16">
          {/* Navigation Buttons */}
          <motion.button 
            onClick={prevSlide}
            className="absolute left-0 top-1/3 -translate-y-1/2 z-10 bg-white dark:bg-gray-800 p-3 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-all focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
            aria-label="Previous articles"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ChevronLeft className="w-6 h-6 text-purple-600 dark:text-purple-400" />
          </motion.button>
          
          <motion.button 
            onClick={nextSlide}
            className="absolute right-0 top-1/3 -translate-y-1/2 z-10 bg-white dark:bg-gray-800 p-3 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-all focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
            aria-label="Next articles"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ChevronRight className="w-6 h-6 text-purple-600 dark:text-purple-400" />
          </motion.button>

          {/* Articles Display */}
          <div className="overflow-hidden">
            <AnimatePresence mode="wait" initial={false}>
              <motion.div 
                key={currentIndex}
                initial={{ opacity: 0, x: direction * 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: direction * -50 }}
                transition={{ 
                  type: "spring", 
                  stiffness: 120, 
                  damping: 20,
                  mass: 1
                }}
                className="grid grid-cols-1 md:grid-cols-2 gap-8"
              >
              {getCurrentArticles().map((article) => (
                <motion.div
                  key={article.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: 0.1
                  }}
                >
                  <ArticleCard article={article} />
                </motion.div>
              ))}
            </motion.div>
            </AnimatePresence>
          </div>
          
          {/* Pagination Indicator */}
          <div className="flex justify-center mt-8 space-x-2">
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  setCurrentIndex(index * articlesPerPage);
                  // Reset timer when clicking pagination dots as well
                  startAutoScroll();
                }}
                className={`h-2 rounded-full transition-all duration-300 ${
                  index === currentPage - 1
                    ? "w-8 bg-purple-600 dark:bg-purple-400"
                    : "w-2 bg-gray-300 dark:bg-gray-600"
                }`}
                aria-label={`Go to page ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticlesSection;