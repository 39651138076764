
// ORGINAL VERSION

// import React from 'react';
// import { motion } from 'framer-motion';

// const HeroSection = () => {
//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3,
//         delayChildren: 0.2
//       }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.5,
//         ease: "easeOut"
//       }
//     }
//   };

//   return (
//     <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-white to-purple-50 dark:from-gray-900 dark:to-purple-900">
//       {/* Animated background elements */}
//       <div className="absolute inset-0 overflow-hidden">
//         <div className="absolute w-96 h-96 -top-10 -left-10 bg-purple-300 dark:bg-purple-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob" />
//         <div className="absolute w-96 h-96 -top-10 -right-10 bg-blue-300 dark:bg-blue-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-2000" />
//         <div className="absolute w-96 h-96 bottom-32 left-10 bg-pink-300 dark:bg-pink-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-4000" />
//       </div>

//       {/* Content */}
//       <motion.div 
//         className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center"
//         variants={containerVariants}
//         initial="hidden"
//         animate="visible"
//       >
//         <motion.h1 
//           className="text-5xl md:text-7xl font-bold mb-8"
//           variants={itemVariants}
//         >
//           <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//             Transform Your Business
//           </span>
//           <br />
//           <span className="text-gray-800 dark:text-white">
//             with AI Solutions
//           </span>
//         </motion.h1>

//         <motion.p 
//           className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 mb-12 max-w-3xl mx-auto"
//           variants={itemVariants}
//         >
//           Unlock the power of AI with our suite of intelligent solutions. 
//           From custom copilots to automated workflows, we're here to 
//           revolutionize your business processes.
//         </motion.p>

        // <motion.div 
        //   className="flex flex-col sm:flex-row gap-4 justify-center items-center"
        //   variants={itemVariants}
        // >
        //   <button className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold text-lg hover:shadow-lg hover:scale-105 transform transition-all duration-200">
        //     Get started!
        //   </button>
        //   {/* <button className="px-8 py-4 rounded-full border-2 border-purple-600 dark:border-purple-400 text-purple-600 dark:text-purple-400 font-semibold text-lg hover:bg-purple-50 dark:hover:bg-purple-900/50 transition-all duration-200">
        //     Contact us!
        //   </button> */}
        // </motion.div>

//         {/* Products Preview */}
//         <motion.div 
//           className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8"
//           variants={containerVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           {/* Cleo Card */}
//           <motion.div
//             className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
//             variants={itemVariants}
//           >
//             <h3 className="text-2xl font-bold mb-4 text-purple-600 dark:text-purple-400">Cleo</h3>
//             <p className="text-gray-600 dark:text-gray-300">
//               Your perfect AI copilot for enhanced customer experience
//             </p>
//           </motion.div>

//           {/* ModelForce Card */}
//           <motion.div
//             className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
//             variants={itemVariants}
//           >
//             <h3 className="text-2xl font-bold mb-4 text-blue-600 dark:text-blue-400">ModelForce</h3>
//             <p className="text-gray-600 dark:text-gray-300">
//               Build and own your custom AI models
//             </p>
//           </motion.div>

//           {/* AgentsLab Card */}
//           <motion.div
//             className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
//             variants={itemVariants}
//           >
//             <h3 className="text-2xl font-bold mb-4 text-pink-600 dark:text-pink-400">AgentsLab</h3>
//             <p className="text-gray-600 dark:text-gray-300">
//               Automate workflows with advanced LLM agents
//             </p>
//           </motion.div>
//         </motion.div>
//       </motion.div>
//     </div>
//   );
// };

// export default HeroSection;



// THIS BELOW IS THE ONE I AM USING AND IT SEEMS TO WORK! ---> FINAL VERSION 1


// import React from 'react';
// import { motion } from 'framer-motion';


// const HeroSection = () => {
//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3,
//         delayChildren: 0.2
//       }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.5,
//         ease: "easeOut"
//       }
//     }
//   };

//   return (
//     <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-white to-purple-50 dark:from-gray-900 dark:to-purple-900">
//       {/* Animated background elements */}
//       <div className="absolute inset-0 overflow-hidden">
//         <div className="absolute w-96 h-96 -top-10 -left-10 bg-purple-300 dark:bg-purple-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob" />
//         <div className="absolute w-96 h-96 -top-10 -right-10 bg-blue-300 dark:bg-blue-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-2000" />
//         <div className="absolute w-96 h-96 bottom-32 left-10 bg-pink-300 dark:bg-pink-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-4000" />
//       </div>

//       {/* Content */}
//       <motion.div 
//         className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center pt-24 md:pt-0"
//         variants={containerVariants}
//         initial="hidden"
//         animate="visible"
//       >
//         <motion.h1 
//           className="text-4xl md:text-7xl font-bold mb-8"
//           variants={itemVariants}
//         >
//           <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//             Transform Your Business
//           </span>
//           <br />
//           <span className="text-gray-800 dark:text-white">
//             with AI Solutions
//           </span>
//         </motion.h1>

//         <motion.p 
//           className="text-lg md:text-2xl text-gray-600 dark:text-gray-300 mb-12 max-w-3xl mx-auto"
//           variants={itemVariants}
//         >
//           Unlock the power of AI with our suite of intelligent solutions. 
//           From custom copilots to automated workflows, we're here to 
//           revolutionize your business processes.
//         </motion.p>

// {/* // This below can be uncommented to get more simpler button animations */}

//         {/* <motion.div 
//           className="flex flex-col sm:flex-row gap-4 justify-center items-center"
//           variants={itemVariants}
//         > */}

//           {/* <button className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold text-lg hover:shadow-lg hover:scale-105 transform transition-all duration-200">
//             Get started!
//           </button> */}
//           {/* <a
//             href="https://app.latentforce.ai/"
//             target="_blank"
//             rel="noopener noreferrer"
//             className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold text-lg hover:shadow-lg hover:scale-105 transform transition-all duration-200"
//           >
//             Get Started
//         </a> */}

//         {/* Get a demo button (scrolls to the Contact Us section) */}
//         {/* <button
//           className="px-8 py-4 rounded-full border-2 border-purple-600 dark:border-purple-400 text-purple-600 dark:text-purple-400 font-semibold text-lg hover:bg-purple-50 dark:hover:bg-purple-900/50 transition-all duration-200"
//           onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
//         >
//           Get a demo
//         </button>
//         </motion.div> */}

// <motion.div 
//   className="flex flex-col sm:flex-row gap-4 justify-center items-center"
//   variants={itemVariants}
// >
//   {/* Get Started button */}
//   <a
//     href="https://app.latentforce.ai/"
//     target="_blank"
//     rel="noopener noreferrer"
//     className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold text-lg hover:shadow-xl hover:scale-110 transform transition-all duration-200"
//   >
//     Get Started
//   </a>

//   {/* Get a Demo button (scrolls to the Contact Us section) */}
//   <button
//     className="px-8 py-4 rounded-full border-4 border-purple-600 text-purple-600 font-semibold text-lg relative overflow-hidden group transition-all duration-300 ease-in-out"
//     onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
//   >
//     {/* Particle Effect */}
//     <div className="absolute inset-0 bg-transparent overflow-hidden group-hover:bg-gradient-to-r from-blue-500 to-purple-600 transition-all duration-500">
//       <div className="absolute inset-0 flex items-center justify-center">
//         <div className="w-16 h-16 bg-gradient-to-r from-blue-600 to-purple-500 rounded-full opacity-40 animate-ping"></div>
//       </div>
//     </div>

//     {/* Button Text */}
//     <span className="relative z-10 text-white group-hover:text-white">
//       Book a demo
//     </span>

//     {/* Lightning Flashes */}
//     <div className="absolute top-0 left-0 right-0 bottom-0 border-2 border-purple-600 rounded-full opacity-0 group-hover:opacity-100 group-hover:animate-lightning"></div>

//     {/* Neon Glow Effect */}
//     <span className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 group-hover:opacity-100 transition-all duration-300"></span>
//   </button>
// </motion.div>

        
        

//         {/* Products Preview */}
//         <motion.div 
//           className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8"
//           variants={containerVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           {/* Cleo Card */}
//           <motion.div
//             className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
//             variants={itemVariants}
//           >
//             <h3 className="text-2xl font-bold mb-4 text-purple-600 dark:text-purple-400">Cleo</h3>
//             <p className="text-gray-600 dark:text-gray-300">
//               Your perfect AI copilot for enhanced customer experience
//             </p>
//           </motion.div>

//           {/* ModelForce Card */}
//           <motion.div
//             className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
//             variants={itemVariants}
//           >
//             <h3 className="text-2xl font-bold mb-4 text-blue-600 dark:text-blue-400">ModelForce</h3>
//             <p className="text-gray-600 dark:text-gray-300">
//               Build and own your custom AI models
//             </p>
//           </motion.div>

//           {/* AgentsLab Card */}
//           <motion.div
//             className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
//             variants={itemVariants}
//           >
//             <h3 className="text-2xl font-bold mb-4 text-pink-600 dark:text-pink-400">AgentsLab</h3>
//             <p className="text-gray-600 dark:text-gray-300">
//               Automate workflows with advanced LLM agents
//             </p>
//           </motion.div>

          

//         </motion.div>
//       </motion.div>
//     </div>
//   );
// };

// export default HeroSection;












import React from 'react';
import { motion } from 'framer-motion';

const HeroSection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-white to-purple-50 dark:from-gray-900 dark:to-purple-900">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 -top-10 -left-10 bg-purple-300 dark:bg-purple-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob" />
        <div className="absolute w-96 h-96 -top-10 -right-10 bg-blue-300 dark:bg-blue-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-2000" />
        <div className="absolute w-96 h-96 bottom-32 left-10 bg-pink-300 dark:bg-pink-700 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-4000" />
      </div>

      {/* Content */}
      <motion.div 
        className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center pt-24 md:pt-0"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h1 
          className="text-4xl md:text-7xl font-bold mb-8"
          variants={itemVariants}
        >
          <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
            Transform Your Business
          </span>
          <br />
          <span className="text-gray-800 dark:text-white">
            with AI Solutions
          </span>
        </motion.h1>

        <motion.p 
          className="text-lg md:text-2xl text-gray-600 dark:text-gray-300 mb-12 max-w-3xl mx-auto"
          variants={itemVariants}
        >
          Unlock the power of AI with our suite of intelligent solutions. 
          From custom copilots to automated workflows, we're here to 
          revolutionize your business processes.
        </motion.p>

        <motion.div 
          className="flex flex-col sm:flex-row gap-4 justify-center items-center"
          variants={itemVariants}
        >
          <a
            href="https://app.latentforce.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold text-lg hover:shadow-xl hover:scale-110 transform transition-all duration-200"
          >
            Get Started
          </a>

          <button
            className="px-8 py-4 rounded-full border-4 border-purple-600 text-purple-600 font-semibold text-lg relative overflow-hidden group transition-all duration-300 ease-in-out"
            onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
          >
            <div className="absolute inset-0 bg-transparent overflow-hidden group-hover:bg-gradient-to-r from-blue-500 to-purple-600 transition-all duration-500">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-16 h-16 bg-gradient-to-r from-blue-600 to-purple-500 rounded-full opacity-40 animate-ping"></div>
              </div>
            </div>

            <span className="relative z-10 text-white group-hover:text-white">
              Book a demo
            </span>

            <div className="absolute top-0 left-0 right-0 bottom-0 border-2 border-purple-600 rounded-full opacity-0 group-hover:opacity-100 group-hover:animate-lightning"></div>

            <span className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 group-hover:opacity-100 transition-all duration-300"></span>
          </button>
        </motion.div>

        {/* Products Preview */}
        <motion.div 
          className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Cleo Card */}
          <motion.div
            className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
            variants={itemVariants}
          >
            <h3 className="text-2xl font-bold mb-4 text-purple-600 dark:text-purple-400">Cleo</h3>
            <p className="text-gray-600 dark:text-gray-300">
              Your perfect AI copilot for enhanced customer experience
            </p>
          </motion.div>

          {/* ModelForce Card */}
          <motion.div
            className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
            variants={itemVariants}
          >
            <h3 className="text-2xl font-bold mb-4 text-blue-600 dark:text-blue-400">ModelForce</h3>
            <p className="text-gray-600 dark:text-gray-300">
              Build and own your custom AI models
            </p>
          </motion.div>

          {/* AgentsLab Card */}
          <motion.div
            className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
            variants={itemVariants}
          >
            <h3 className="text-2xl font-bold mb-4 text-pink-600 dark:text-pink-400">AgentsLab</h3>
            <p className="text-gray-600 dark:text-gray-300">
              Automate workflows with advanced LLM agents
            </p>
          </motion.div>

          {/* Extractors Card */}
          <motion.div
            className="p-6 rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
            variants={itemVariants}
          >
            <h3 className="text-2xl font-bold mb-4 text-emerald-600 dark:text-emerald-400">Extractors</h3>
            <p className="text-gray-600 dark:text-gray-300">
              AI-powered document extraction and processing
            </p>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HeroSection;


















// Orange theme BASED FUTURE OF AI!!!!

// import React, { useState, useEffect } from 'react';
// import { motion, useAnimation } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import { Cpu, Zap, Grid } from 'lucide-react';

// const HeroSection = () => {
//   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
//   const controls = useAnimation();
//   const [ref, inView] = useInView();

//   // Neural network nodes for background
//   const nodes = Array(15).fill().map((_, i) => ({
//     id: i,
//     x: Math.random() * 100,
//     y: Math.random() * 100,
//     connections: Math.floor(Math.random() * 3) + 1
//   }));

//   useEffect(() => {
//     if (inView) {
//       controls.start('visible');
//     }
//   }, [controls, inView]);

//   const handleMouseMove = (e) => {
//     const { clientX, clientY } = e;
//     const { innerWidth, innerHeight } = window;
//     setMousePosition({
//       x: (clientX / innerWidth) * 2 - 1,
//       y: (clientY / innerHeight) * 2 - 1
//     });
//   };

//   return (
//     <div 
//       className="relative min-h-screen overflow-hidden bg-black"
//       onMouseMove={handleMouseMove}
//       ref={ref}
//     >
//       {/* Animated Background */}
//       <div className="absolute inset-0">
//         {/* Glowing gradient orb */}
//         <div 
//           className="absolute w-[800px] h-[800px] rounded-full"
//           style={{
//             background: 'radial-gradient(circle, rgba(255,107,0,0.3) 0%, rgba(0,0,0,0) 70%)',
//             transform: `translate(${mousePosition.x * 100}px, ${mousePosition.y * 100}px)`,
//             top: '50%',
//             left: '50%',
//             marginLeft: '-400px',
//             marginTop: '-400px',
//             filter: 'blur(60px)',
//             transition: 'transform 0.1s ease-out'
//           }}
//         />

//         {/* Neural Network Grid */}
//         <div className="absolute inset-0">
//           {nodes.map((node, i) => (
//             <motion.div
//               key={i}
//               className="absolute w-2 h-2 bg-orange-500 rounded-full"
//               style={{
//                 left: `${node.x}%`,
//                 top: `${node.y}%`,
//                 filter: 'blur(1px)',
//                 boxShadow: '0 0 20px rgba(255,107,0,0.5)'
//               }}
//               animate={{
//                 scale: [1, 1.5, 1],
//                 opacity: [0.5, 1, 0.5]
//               }}
//               transition={{
//                 duration: 2 + Math.random() * 2,
//                 repeat: Infinity,
//                 repeatType: "reverse"
//               }}
//             >
//               {/* Connection lines */}
//               {Array(node.connections).fill().map((_, j) => {
//                 const targetNode = nodes[(i + j + 1) % nodes.length];
//                 const dx = targetNode.x - node.x;
//                 const dy = targetNode.y - node.y;
//                 const angle = Math.atan2(dy, dx) * 180 / Math.PI;
//                 const distance = Math.sqrt(dx * dx + dy * dy);
                
//                 return (
//                   <motion.div
//                     key={j}
//                     className="absolute origin-left h-px bg-gradient-to-r from-orange-500/50 to-transparent"
//                     style={{
//                       width: `${distance}%`,
//                       transform: `rotate(${angle}deg)`,
//                     }}
//                     animate={{
//                       opacity: [0.2, 0.5, 0.2],
//                       scaleX: [1, 0.8, 1]
//                     }}
//                     transition={{
//                       duration: 3,
//                       repeat: Infinity,
//                       repeatType: "reverse"
//                     }}
//                   />
//                 );
//               })}
//             </motion.div>
//           ))}
//         </div>
//       </div>

//       {/* Content */}
//       <div className="relative z-10 container mx-auto px-6 pt-32">
//         <motion.div
//           className="text-center"
//           initial="hidden"
//           animate="visible"
//           variants={{
//             hidden: { opacity: 0 },
//             visible: { opacity: 1 }
//           }}
//         >
//           {/* Animated Logo */}
//           <motion.div
//             className="mb-12"
//             initial={{ scale: 0 }}
//             animate={{ scale: 1 }}
//             transition={{
//               type: "spring",
//               stiffness: 260,
//               damping: 20
//             }}
//           >
//             <img src="/logo.png" alt="LatentForce.ai" className="h-20 mx-auto" />
//           </motion.div>

//           {/* Main Heading with Glitch Effect */}
//           <motion.div
//             className="relative mb-8"
//             initial={{ y: 50, opacity: 0 }}
//             animate={{ y: 0, opacity: 1 }}
//             transition={{ delay: 0.3 }}
//           >
//             <h1 className="text-6xl md:text-8xl font-bold text-white mb-2 relative">
//               <span className="relative inline-block">
//                 Future of AI
//                 <motion.span
//                   className="absolute inset-0 text-orange-500"
//                   animate={{
//                     x: [0, -2, 2, 0],
//                     opacity: [1, 0.8, 0.9, 1]
//                   }}
//                   transition={{
//                     duration: 0.2,
//                     repeat: Infinity,
//                     repeatType: "reverse"
//                   }}
//                 >
//                   Future of AI
//                 </motion.span>
//               </span>
//             </h1>
//             <motion.p 
//               className="text-2xl text-gray-400 max-w-3xl mx-auto"
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ delay: 0.5 }}
//             >
//               Transforming Businesses with Next-Generation AI Solutions
//             </motion.p>
//           </motion.div>

//           {/* Feature Icons */}
//           <motion.div 
//             className="flex justify-center gap-12 mb-12"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 0.7 }}
//           >
//             {[
//               { Icon: Cpu, text: "Advanced AI" },
//               { Icon: Zap, text: "Real-time Processing" },
//               { Icon: Grid, text: "Scalable Solutions" }
//             ].map(({ Icon, text }, index) => (
//               <motion.div
//                 key={text}
//                 className="text-center"
//                 whileHover={{ scale: 1.1 }}
//                 transition={{ type: "spring", stiffness: 400, damping: 10 }}
//               >
//                 <div className="relative">
//                   <motion.div
//                     className="absolute inset-0 bg-orange-500/20 rounded-full"
//                     animate={{
//                       scale: [1, 1.2, 1],
//                       opacity: [0.5, 0.2, 0.5]
//                     }}
//                     transition={{
//                       duration: 2,
//                       repeat: Infinity,
//                       delay: index * 0.2
//                     }}
//                   />
//                   <Icon size={48} className="text-orange-500 relative z-10 mx-auto mb-2" />
//                 </div>
//                 <span className="text-gray-300">{text}</span>
//               </motion.div>
//             ))}
//           </motion.div>

//           {/* CTA Buttons */}
//           <motion.div 
//             className="flex justify-center gap-6"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 0.9 }}
//           >
//             <motion.button
//               className="px-8 py-4 bg-orange-500 text-white rounded-lg font-semibold relative overflow-hidden group"
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//             >
//               <span className="relative z-10">Book a Demo</span>
//               <motion.div
//                 className="absolute inset-0 bg-gradient-to-r from-orange-600 to-orange-400"
//                 initial={{ x: "100%" }}
//                 whileHover={{ x: 0 }}
//                 transition={{ type: "spring", stiffness: 100, damping: 15 }}
//               />
//             </motion.button>
//             <motion.button
//               className="px-8 py-4 bg-transparent text-white border-2 border-orange-500 rounded-lg font-semibold relative overflow-hidden group"
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//             >
//               <span className="relative z-10">Try Now</span>
//               <motion.div
//                 className="absolute inset-0 bg-orange-500"
//                 initial={{ scale: 0 }}
//                 whileHover={{ scale: 1 }}
//                 transition={{ type: "spring", stiffness: 100, damping: 15 }}
//               />
//             </motion.button>
//           </motion.div>
//         </motion.div>
//       </div>

//       {/* Sci-fi Scroll Indicator */}
//       <motion.div 
//         className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
//         animate={{
//           y: [0, 10, 0],
//           opacity: [0.5, 1, 0.5]
//         }}
//         transition={{
//           duration: 2,
//           repeat: Infinity,
//           ease: "easeInOut"
//         }}
//       >
//         <div className="w-8 h-16 border-2 border-orange-500 rounded-full relative">
//           <motion.div
//             className="w-2 h-2 bg-orange-500 rounded-full absolute left-1/2 top-2 transform -translate-x-1/2"
//             animate={{
//               y: [0, 24, 0]
//             }}
//             transition={{
//               duration: 1.5,
//               repeat: Infinity,
//               ease: "easeInOut"
//             }}
//           />
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default HeroSection;







// ORANGE THEME BASED ANIMATIONS V1


// import React, { useEffect, useRef, useState } from 'react';
// import { motion, useScroll, useTransform } from 'framer-motion';
// import { Camera } from 'lucide-react';

// const HeroSection = () => {
//   const containerRef = useRef(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const { scrollY } = useScroll();
//   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

//   const textY = useTransform(scrollY, [0, 300], [0, -50]);
//   const opacity = useTransform(scrollY, [0, 300], [1, 0]);

//   useEffect(() => {
//     setIsLoaded(true);
//   }, []);

//   const handleMouseMove = (e) => {
//     const { clientX, clientY } = e;
//     const { width, height } = containerRef.current.getBoundingClientRect();
//     const x = (clientX / width - 0.5) * 2;
//     const y = (clientY / height - 0.5) * 2;
//     setMousePosition({ x, y });
//   };

//   return (
//     <div
//       ref={containerRef}
//       className="relative min-h-screen overflow-hidden bg-black"
//       onMouseMove={handleMouseMove}
//     >
//       {/* Animated Background Elements */}
//       <div className="absolute inset-0 bg-black">
//         {/* Neural Network Grid */}
//         <div className="absolute inset-0 grid grid-cols-12 grid-rows-12 gap-4 opacity-20">
//           {[...Array(144)].map((_, i) => (
//             <motion.div
//               key={i}
//               className="relative"
//               initial={{ scale: 0, opacity: 0 }}
//               animate={{ 
//                 scale: 1, 
//                 opacity: 0.5,
//                 x: mousePosition.x * (Math.random() * 20),
//                 y: mousePosition.y * (Math.random() * 20)
//               }}
//               transition={{ 
//                 duration: 1,
//                 delay: i * 0.01,
//                 type: "spring"
//               }}
//             >
//               <div className="absolute inset-0 bg-orange-500 rounded-full transform scale-50 blur-sm" />
//               <div className="absolute inset-0 bg-blue-500 rounded-full mix-blend-screen transform scale-75" />
//             </motion.div>
//           ))}
//         </div>

//         {/* Animated Gradient Orbs */}
//         {[...Array(5)].map((_, i) => (
//           <motion.div
//             key={`orb-${i}`}
//             className="absolute rounded-full mix-blend-screen filter blur-xl"
//             initial={{ scale: 0 }}
//             animate={{
//               scale: [1, 2, 1],
//               x: [
//                 Math.random() * 1000,
//                 Math.random() * 1000,
//                 Math.random() * 1000
//               ],
//               y: [
//                 Math.random() * 1000,
//                 Math.random() * 1000,
//                 Math.random() * 1000
//               ],
//             }}
//             transition={{
//               duration: 10,
//               repeat: Infinity,
//               repeatType: "reverse",
//               ease: "linear"
//             }}
//             style={{
//               width: '400px',
//               height: '400px',
//               background: `radial-gradient(circle, ${
//                 i % 2 === 0 ? '#ff6b00' : '#0080ff'
//               } 0%, transparent 70%)`,
//               left: `${Math.random() * 100}%`,
//               top: `${Math.random() * 100}%`,
//             }}
//           />
//         ))}

//         {/* Digital Rain Effect */}
//         <div className="absolute inset-0 overflow-hidden opacity-20">
//           {[...Array(20)].map((_, i) => (
//             <motion.div
//               key={`rain-${i}`}
//               className="absolute w-px bg-gradient-to-b from-transparent via-orange-500 to-transparent"
//               style={{
//                 left: `${i * 5}%`,
//                 height: '100%',
//                 top: '-100%'
//               }}
//               animate={{
//                 y: ['0%', '200%']
//               }}
//               transition={{
//                 duration: Math.random() * 2 + 1,
//                 repeat: Infinity,
//                 ease: 'linear',
//                 delay: Math.random() * 2
//               }}
//             />
//           ))}
//         </div>
//       </div>

//       {/* Content Layer */}
//       <motion.div 
//         className="relative z-10 container mx-auto px-6 h-screen flex flex-col justify-center items-center"
//         style={{ y: textY, opacity }}
//       >
//         {/* Animated Logo */}
//         <motion.div
//           className="mb-12"
//           initial={{ scale: 0, rotate: -180 }}
//           animate={{ scale: 1, rotate: 0 }}
//           transition={{ 
//             type: "spring",
//             stiffness: 200,
//             damping: 20
//           }}
//         >
//           <div className="relative w-32 h-32">
//             <motion.div
//               className="absolute inset-0 bg-orange-500 rounded-full opacity-75"
//               animate={{
//                 scale: [1, 1.2, 1],
//               }}
//               transition={{
//                 duration: 2,
//                 repeat: Infinity,
//                 ease: "easeInOut"
//               }}
//             />
//             <img 
//               src="/logo.png" 
//               alt="LatentForce.ai" 
//               className="relative z-10 w-full h-full object-contain"
//             />
//           </div>
//         </motion.div>

//         {/* Main Title with Glitch Effect */}
//         <motion.h1
//           className="text-7xl md:text-8xl font-black text-white mb-8 relative"
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ delay: 0.5 }}
//         >
//           <span className="relative">
//             <span className="absolute -inset-0.5 bg-orange-500 opacity-30 blur-lg" />
//             <span className="relative">LatentForce.ai</span>
//           </span>
//         </motion.h1>

//         {/* Animated Subtitle */}
//         <motion.p
//           className="text-2xl md:text-3xl text-gray-300 mb-12 text-center max-w-3xl"
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ delay: 0.7 }}
//         >
//           Where AI Meets Innovation
//         </motion.p>

//         {/* CTA Buttons */}
//         <motion.div
//           className="flex gap-6"
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ delay: 0.9 }}
//         >
//           <motion.button
//             className="px-8 py-4 bg-orange-500 text-white rounded-lg font-bold relative overflow-hidden group"
//             whileHover={{ scale: 1.05 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             <span className="relative z-10">Book a Demo</span>
//             <motion.div
//               className="absolute inset-0 bg-gradient-to-r from-orange-600 to-orange-400"
//               initial={{ x: '100%' }}
//               whileHover={{ x: 0 }}
//               transition={{ type: 'tween' }}
//             />
//           </motion.button>

//           <motion.button
//             className="px-8 py-4 bg-transparent text-white border-2 border-white rounded-lg font-bold relative overflow-hidden group"
//             whileHover={{ scale: 1.05 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             <span className="relative z-10">Try Now</span>
//             <motion.div
//               className="absolute inset-0 bg-white"
//               initial={{ height: 0 }}
//               whileHover={{ height: '100%' }}
//               transition={{ type: 'tween' }}
//             />
//             <span className="absolute inset-0 flex items-center justify-center font-bold opacity-0 group-hover:opacity-100 group-hover:text-orange-500 transition-opacity">
//               Try Now
//             </span>
//           </motion.button>
//         </motion.div>
//       </motion.div>

//       {/* Scroll Indicator */}
//       <motion.div
//         className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white"
//         animate={{ y: [0, 10, 0] }}
//         transition={{ duration: 1.5, repeat: Infinity }}
//       >
//         <div className="text-center">
//           <div className="w-8 h-14 border-2 border-white rounded-full relative mx-auto mb-2">
//             <motion.div
//               className="w-1.5 h-1.5 bg-white rounded-full absolute left-1/2 top-2 transform -translate-x-1/2"
//               animate={{ y: [0, 8, 0] }}
//               transition={{ duration: 1.5, repeat: Infinity }}
//             />
//           </div>
//           <span className="text-sm font-medium opacity-75">Scroll to Explore</span>
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default HeroSection;

















// import React, { useState, useEffect, useRef } from 'react';
// import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
// import { Camera } from 'lucide-react';

// const HeroSection = () => {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const containerRef = useRef(null);
//   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
//   const [cursorVariant, setCursorVariant] = useState("default");

//   useEffect(() => {
//     setIsLoaded(true);
//     const timer = setTimeout(() => {
//       document.body.style.overflow = 'auto';
//     }, 2000);
//     return () => clearTimeout(timer);
//   }, []);

//   const handleMouseMove = (e) => {
//     const { clientX, clientY } = e;
//     const { left, top } = containerRef.current.getBoundingClientRect();
//     setMousePosition({ 
//       x: clientX - left, 
//       y: clientY - top 
//     });
//   };

//   // Custom cursor variants
//   const cursorVariants = {
//     default: {
//       x: mousePosition.x - 16,
//       y: mousePosition.y - 16,
//       scale: 1
//     },
//     hover: {
//       x: mousePosition.x - 32,
//       y: mousePosition.y - 32,
//       scale: 2
//     }
//   };

//   return (
//     <div 
//       ref={containerRef}
//       className="relative min-h-screen overflow-hidden bg-black"
//       onMouseMove={handleMouseMove}
//     >
//       {/* Initial Loading Animation */}
//       <AnimatePresence>
//         {!isLoaded && (
//           <motion.div
//             initial={{ scale: 1 }}
//             exit={{ scale: 0 }}
//             transition={{ duration: 0.5 }}
//             className="fixed inset-0 z-50 bg-black flex items-center justify-center"
//           >
//             <motion.div
//               animate={{ rotate: 360 }}
//               transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//               className="w-16 h-16 border-t-2 border-orange-500 rounded-full"
//             />
//           </motion.div>
//         )}
//       </AnimatePresence>

//       {/* Custom Cursor */}
//       <motion.div
//         className="hidden md:block fixed top-0 left-0 w-8 h-8 bg-orange-500 rounded-full mix-blend-difference pointer-events-none z-50"
//         variants={cursorVariants}
//         animate={cursorVariant}
//         transition={{ type: "spring", stiffness: 500, damping: 28 }}
//       />

//       {/* Dynamic Background Elements */}
//       <div className="absolute inset-0">
//         {/* Animated Grid */}
//         <div className="absolute inset-0 opacity-20">
//           {[...Array(20)].map((_, i) => (
//             <motion.div
//               key={`grid-${i}`}
//               className="absolute w-full h-px bg-gradient-to-r from-transparent via-orange-500 to-transparent"
//               initial={{ opacity: 0, y: i * 50 }}
//               animate={{ 
//                 opacity: [0, 1, 0],
//                 y: [i * 50, i * 50 + 25, i * 50 + 50]
//               }}
//               transition={{
//                 duration: 3,
//                 repeat: Infinity,
//                 delay: i * 0.1
//               }}
//             />
//           ))}
//         </div>

//         {/* Floating Elements */}
//         {[...Array(10)].map((_, i) => (
//           <motion.div
//             key={`float-${i}`}
//             className="absolute rounded-full"
//             style={{
//               width: Math.random() * 100 + 50,
//               height: Math.random() * 100 + 50,
//               left: `${Math.random() * 100}%`,
//               top: `${Math.random() * 100}%`,
//               background: `radial-gradient(circle at center, rgba(255,107,0,0.2), transparent)`,
//             }}
//             animate={{
//               scale: [1, 1.5, 1],
//               x: [0, Math.random() * 100 - 50, 0],
//               y: [0, Math.random() * 100 - 50, 0],
//               opacity: [0.3, 0.6, 0.3]
//             }}
//             transition={{
//               duration: Math.random() * 5 + 5,
//               repeat: Infinity,
//               ease: "easeInOut"
//             }}
//           />
//         ))}
//       </div>

//       {/* Main Content */}
//       <div className="relative z-10 container mx-auto px-6">
//         <div className="min-h-screen flex flex-col items-center justify-center">
//           {/* Logo Animation */}
//           <motion.div
//             initial={{ scale: 0, rotate: -180 }}
//             animate={{ scale: 1, rotate: 0 }}
//             transition={{ 
//               duration: 1,
//               type: "spring",
//               stiffness: 200
//             }}
//             className="mb-12"
//           >
//             <div className="relative w-32 h-32">
//               <motion.div
//                 animate={{ 
//                   rotate: 360,
//                   scale: [1, 1.1, 1]
//                 }}
//                 transition={{ 
//                   rotate: { duration: 20, repeat: Infinity, ease: "linear" },
//                   scale: { duration: 2, repeat: Infinity, ease: "easeInOut" }
//                 }}
//                 className="absolute inset-0 rounded-full border-2 border-orange-500 opacity-50"
//               />
//               <img 
//                 src="/logo.png" 
//                 alt="LatentForce.ai" 
//                 className="relative z-10 w-full h-full object-contain"
//               />
//             </div>
//           </motion.div>

//           {/* Main Title with Glitch Effect */}
//           <motion.h1
//             className="text-7xl md:text-9xl font-bold text-white mb-8 relative"
//             initial={{ opacity: 0, y: 50 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 0.5 }}
//           >
//             <span className="relative">
//               <span className="absolute -inset-0.5 text-orange-500 animate-glitch-1">
//                 LatentForce.ai
//               </span>
//               <span className="absolute -inset-0.5 text-cyan-500 animate-glitch-2">
//                 LatentForce.ai
//               </span>
//               <span className="relative text-white">
//                 LatentForce.ai
//               </span>
//             </span>
//           </motion.h1>

//           {/* Animated Description */}
//           <motion.div
//             className="relative max-w-4xl text-center mb-12"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 0.8 }}
//           >
//             <motion.p 
//               className="text-2xl md:text-3xl text-gray-300 leading-relaxed"
//               animate={{ 
//                 background: ["linear-gradient(45deg, #ff6b00, #ff6b00)", "linear-gradient(45deg, #ff6b00, #ffffff)"],
//                 backgroundClip: "text",
//                 WebkitBackgroundClip: "text",
//                 WebkitTextFillColor: "transparent"
//               }}
//               transition={{ duration: 3, repeat: Infinity, repeatType: "reverse" }}
//             >
//               Unleashing Next-Gen AI Solutions
//             </motion.p>
//           </motion.div>

//           {/* CTA Buttons */}
//           <motion.div 
//             className="flex flex-wrap justify-center gap-6"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 1 }}
//           >
//             <motion.button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               className="group relative px-8 py-4 bg-transparent overflow-hidden"
//             >
//               <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-orange-500 group-hover:bg-orange-600 group-hover:skew-x-12"></span>
//               <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-orange-600 group-hover:bg-orange-500 group-hover:-skew-x-12"></span>
//               <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-orange-600 -rotate-12"></span>
//               <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-orange-400 -rotate-12"></span>
//               <span className="relative text-white text-lg font-bold">Book a Demo</span>
//             </motion.button>

//             <motion.button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               className="group relative px-8 py-4 bg-transparent overflow-hidden"
//             >
//               <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-white group-hover:bg-gray-100 group-hover:skew-x-12"></span>
//               <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-gray-100 group-hover:bg-white group-hover:-skew-x-12"></span>
//               <span className="relative text-orange-500 text-lg font-bold">Try Now</span>
//             </motion.button>
//           </motion.div>
//         </div>
//       </div>

//       {/* Scroll Indicator */}
//       <motion.div 
//         className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex flex-col items-center"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ delay: 1.5 }}
//       >
//         <motion.div
//           animate={{ y: [0, 10, 0] }}
//           transition={{ duration: 1.5, repeat: Infinity }}
//           className="text-white opacity-50"
//         >
//           <motion.div 
//             className="w-1 h-16 relative overflow-hidden mx-auto"
//             style={{ background: 'linear-gradient(to bottom, transparent, #ff6b00)' }}
//           >
//             <motion.div
//               className="absolute top-0 left-0 w-full h-full bg-white"
//               animate={{ y: ['-100%', '100%'] }}
//               transition={{ duration: 1.5, repeat: Infinity }}
//             />
//           </motion.div>
//         </motion.div>
//       </motion.div>

//       {/* Add custom animations in your CSS */}
//       <style jsx>{`
//         @keyframes glitch-1 {
//           0% { clip-path: inset(40% 0 61% 0); transform: translate(-2px, 2px); }
//           20% { clip-path: inset(92% 0 1% 0); transform: translate(1px, -3px); }
//           40% { clip-path: inset(43% 0 1% 0); transform: translate(-1px, 3px); }
//           60% { clip-path: inset(25% 0 58% 0); transform: translate(3px, 1px); }
//           80% { clip-path: inset(54% 0 7% 0); transform: translate(-3px, -2px); }
//           100% { clip-path: inset(58% 0 43% 0); transform: translate(2px, 2px); }
//         }

//         @keyframes glitch-2 {
//           0% { clip-path: inset(31% 0 40% 0); transform: translate(3px, -4px); }
//           20% { clip-path: inset(13% 0 58% 0); transform: translate(-3px, 2px); }
//           40% { clip-path: inset(96% 0 3% 0); transform: translate(1px, -3px); }
//           60% { clip-path: inset(23% 0 62% 0); transform: translate(2px, 4px); }
//           80% { clip-path: inset(76% 0 5% 0); transform: translate(-4px, -2px); }
//           100% { clip-path: inset(43% 0 27% 0); transform: translate(3px, 3px); }
//         }

//         .animate-glitch-1 {
//           animation: glitch-1 0.4s infinite linear alternate-reverse;
//         }

//         .animate-glitch-2 {
//           animation: glitch-2 0.4s infinite linear alternate-reverse;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default HeroSection;